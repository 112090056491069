

  /*
    @import "~bulma/sass/utilities/mixins";
    @import "@/scss/_bulmaOverridden";
  */
.card {
  cursor: pointer;
}

